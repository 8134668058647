<script setup lang="ts">
import InfoHref from '~/components/shared/Common/InfoHref.vue';

const config = useRuntimeConfig();
const personalDataFileUrl = config.public.personalDataFileUrl;
const cookiePolicyUrl = config.public.cookiePolicyUrl;
const errorTextEmail = config.public.errorTextEmail;
</script>

<template>
    <div class="footer">
        <div class="item">
            <a href="https://kortros.ru/politika-obrabotki-personalnyh-dannyh.pdf" target="_blank">{{ $t('forms.shared.phrases.personalPolicy') }}</a>
        </div>
        <div class="item">
            <InfoHref
                :fileType="'txt'"
                :dialogTitle="$t('forms.shared.phrases.cookiePolicy')"
                :fileUrl="cookiePolicyUrl"
            >
            </InfoHref>
        </div>
        <div class="item">
            <a :href="personalDataFileUrl" target="_blank">{{ $t('forms.shared.phrases.personalData') }}</a>
        </div>
        <div class="item">
            <span>
                {{ $t('forms.shared.phrases.feedback') }} <a :href="`mailto: ${errorTextEmail}`" class="link">{{ errorTextEmail }}</a>
            </span>
        </div>
    </div>
</template>

<style scoped lang="scss">
    .footer {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: flex-start;
        align-content: flex-start;
        gap: 8px var(--Padding-pa-6, 24px);
        flex-wrap: wrap;

        color: var(--Color-Zink-400, $main-text-color);
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: -0.13px;

        @include responsive( 'mobile' ) {
            display: flex;
            padding: var(--Padding-pa-8, 32px) var(--Padding-pa-0, 0px);
            flex-direction: column;
            justify-content: center;
            align-content: center;
            align-items: center;
            gap: var(--Padding-pa-8, 32px);
        }

        .item {
            color: var(--Color-Zink-500, $secondary-text-color);
            text-align: center;

            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 125%; /* 17.5px */
            letter-spacing: -0.14px;
        }
    }
</style>
